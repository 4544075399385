<template>
  <a-modal
    v-model:visible="state.visible"
    :title="$t('warehouse.edit_box_info')"
    :centered="true"
    :mask-closable="false"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-form
      ref="formRef"
      :model="state.formState"
      :rules="rules"
      :label-col="{ style: { width: '150px' } }"
    >
      <a-form-item :label="$t('common.max_length_side')" name="containerLength">
        <a-input-number v-model:value="state.formState.containerLength" :min="1" :precision="0" style="width: 180px;" /> cm
      </a-form-item>
      <a-form-item :label="$t('common.second_length_side')" name="containerWidth">
        <a-input-number v-model:value="state.formState.containerWidth" :min="1" :precision="0" style="width: 180px;" /> cm
      </a-form-item>
      <a-form-item :label="$t('common.least_length_side')" name="containerHeight">
        <a-input-number v-model:value="state.formState.containerHeight" :min="1" :precision="0" style="width: 180px;" /> cm
      </a-form-item>
      <a-form-item :label="$t('logistics.weight')" name="containerWeight">
        <a-input-number v-model:value="state.formState.containerWeight" :min="0.001" :precision="3" style="width: 180px;" /> kg
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, watch } from 'vue';
import { Form, InputNumber, Modal, message } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { updateTransportPlanBoxInfo } from "@/api/modules/transportation/index";
import { kgToG } from "@/utils/general.js";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    AInputNumber: InputNumber,
  },
  emits: ['update:visible', 'refresh'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const formRef = ref(null);
    
    const state = reactive({
      visible: false,
      confirmLoading: false,
      formState: {
        id: null,
        boxId: null,
        containerLength: null,
        containerWidth: null,
        containerHeight: null,
        containerWeight: null,
      }
    });

    const rules = {
      containerLength: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("common.max_length_side")]),
      },
      containerWidth: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("common.second_length_side")]),
      },
      containerHeight: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("common.least_length_side")]),
      },
      containerWeight: {
        required: true,
        message: () => vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.weight")]),
      },
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate();
        state.confirmLoading = true;
        const data = Object.assign({}, state.formState);
        let sizeArr = [data.containerLength, data.containerWidth, data.containerHeight].sort((a, b) => b - a);
        data.containerLength = sizeArr[0];
        data.containerWidth = sizeArr[1];
        data.containerHeight = sizeArr[2];
        data.containerWeight = kgToG(data.containerWeight);
        await updateTransportPlanBoxInfo(data)
        message.success(vueI18n.t('common.succeed'));
        emit('refresh');
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const close = () => {
      emit('update:visible', false);
    }

    watch(() => props.info, (info) => {
      if (typeof info === 'object' && info) {
        if ('id' in info) {
          nextTick(() => {
            formRef.value.resetFields();
            state.formState.id = info.planId;
            state.formState.boxId = info.boxId;
            state.formState.containerLength = info.encasementLength;
            state.formState.containerWidth = info.encasementWidth;
            state.formState.containerHeight = info.encasementHeight;
            state.formState.containerWeight = info.encasementGrossWeight;
          });
        }
      }
    }, { deep: true });

    watch(() => props.visible, (visible) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
      }
    }, { immediate: true });

    return {
      state,
      formRef,
      rules,
      handleConfirm,
      close,
    }
  }
})
</script>

<style scoped>

</style>